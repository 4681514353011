
import { Component, Vue } from 'vue-property-decorator';

export enum Transition {
    OutLeft = 'out-left',
    OutRight = 'out-right',
    InLeft = 'in-left',
    InRight = 'in-right',
    In = 'in'
}
@Component({})
export default class AnimatedSheet extends Vue {
    sheetCssClass = '';

    transitionTo(val: Transition) {
        switch (val) {
            case Transition.InLeft:
            case Transition.InRight:
                this.sheetCssClass = `${val}`;
                setTimeout(() => this.sheetCssClass = `${val} in animated`, 0);
                break;
            case Transition.In:
                this.sheetCssClass = val;
                break;
            case Transition.OutLeft:
            case Transition.OutRight:
                this.sheetCssClass = `${val} animated`;
        }
    }
}
