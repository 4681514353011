
import { Component, Watch } from 'vue-property-decorator';
import NavDrawer from './components/NavDrawer.vue';
import AppFooter from './views/AppFooter.vue';
import Vue from 'vue';
import { Route } from 'node_modules/vue-router/types';
import { delay } from './timing';

@Component({
  components: {
    NavDrawer,
    AppFooter
  }
})
export default class App extends Vue {

  async mounted() {
    const preloader = document.getElementById('pre');
    if (preloader) {
      await delay(200);
      preloader.style.opacity = '0';
      
      delay(1200).then(() => preloader.parentNode!!.removeChild(preloader));
    }
  }

  @Watch('$route', { immediate: true, deep: true})
  onRouteUpdate(value: Route) {
    document.title = this.computeRouteTitle(value);
  }

  computeRouteTitle(route: Route) {
    const routeTitle = route.meta?.title as string;
    const title = 'team sanos | Osteopathie und Physiotherapie';
    return routeTitle ? routeTitle + ' | ' + title : title;
  }

  get footerInvisible() {
    return !!this.$route.meta?.footerInvisible;
  }
}
